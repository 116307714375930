<template>
  <div style="position: relative; width: 100vw; min-height: 100vh; overflow: hidden">
    <LandingBackground/>
    <div class="ytm-landing-wrapper">
      <LandingTop @scrollToFooter="goto('footer')"/>
      <div style="position: relative; width: 100%">
        <svg class="ytm-landing-button-back" @click="$router.push('/')" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="36.5" cy="36.5" r="36.5" fill="#A23DF1"/>
          <path d="M55 38.5C55.8284 38.5 56.5 37.8284 56.5 37C56.5 36.1716 55.8284 35.5 55 35.5L55 38.5ZM21.9393 35.9393C21.3536 36.5251 21.3536 37.4749 21.9393 38.0607L31.4853 47.6066C32.0711 48.1924 33.0208 48.1924 33.6066 47.6066C34.1924 47.0208 34.1924 46.0711 33.6066 45.4853L25.1213 37L33.6066 28.5147C34.1924 27.9289 34.1924 26.9792 33.6066 26.3934C33.0208 25.8076 32.0711 25.8076 31.4853 26.3934L21.9393 35.9393ZM55 35.5L23 35.5L23 38.5L55 38.5L55 35.5Z" fill="white"/>
        </svg>
        <h1 class="ytm-landing-font ytm-landing-chapter-head" style="margin-top: 4vh; margin-bottom: max(4vh, 4vw)">
          Возможности YouTeachMe
        </h1>
      </div>
      <div class="ytm-features-cards">
        <Feature v-for="(feature, i) in features" :key="i" v-bind="feature"/>
      </div>
      <JoinUs/>
      <Footer ref="footer"/>
    </div>
  </div>
</template>

<script>
import LandingBackground from "../Background";
import LandingTop from "../Navbar";
import Footer from "../Footer";
import JoinUs from "../JoinUs";
import Feature from "./Feature";

export default {
  name: 'FeaturesPage',
  components: {Feature, LandingBackground, LandingTop, Footer, JoinUs},
  data() {
    return {
      features: [
        {
          head: 'Система курсов и занятий',
          desc: 'Создайте индивидуальный или групповой курс, пригласите туда ученика/-ов и проводите занятия',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/book-shelf.png',
        },
        {
          head: 'Структура занятия',
          desc: 'Все наработки сохраняются после урока, и ученик в любой момент может к ним вернуться',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/tuition--v1.png',
        },
        {
          head: 'Совместный текстовый редактор',
          desc: 'Совместная работа на уроке делает занятие намного интереснее и позволяет лучше закрепить материал',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/document--v1.png',
        },
        {
          head: 'Встроенная онлайн-доска',
          desc: 'Доска для совместного рисования с множеством инструментов и отображением курсоров участников',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/whiteboard.png',
        },
        {
          head: 'Тесты с автоматической проверкой',
          desc: 'Можно выбрать формат вопросов (в том числе с развёрнутым ответом), установить разбалловку и дедлайн',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/survey--v1.png',
        },
        {
          head: 'Математические формулы',
          desc: 'Используйте встроенную клавиатуру или редактор LaTeX для добавления формул прямо в текстовом редакторе',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/square-root.png',
        },
        {
          head: 'Совместный редактор кода',
          desc: 'Пишите код вместе на самых разных языках программирования с отображением курсоров и выделений',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/code.png',
        },
        {
          head: 'Домашнее задание',
          desc: 'После урока можно оставлять домашнее задание, следить за его выполнением и оставлять комментарии',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/homework.png',
        },
        {
          head: 'Мессенджер',
          desc: 'Встроенные чаты для общения с учениками и родителями освободят ваш личный мессенджер от рабочих вопросов',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/messaging-.png',
        },
        {
          head: 'Контроль оплат',
          desc: 'Надоело следить, кто оплатил наперёд, а кто задолжал? Вся необходимая аналитика в личном кабинете',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/cheque.png',
        },
        {
          head: 'Встроенная видеосвязь',
          desc: 'Аудио- и видеосвязь на платформе, чтобы не уходить на сторонние сервисы',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/video-call.png',
          todo: true,
        },
        {
          head: 'Профиль с отзывами и поиск учеников',
          desc: 'Возможность выставлять свою анкету репетитора и находить учеников',
          icon: 'https://img.icons8.com/ios-glyphs/90/000000/user--v1.png',
          todo: true,
        },
      ],
    };
  },
    methods: {
        goto(ref_name) {
            let element;
            switch (ref_name) {
                case 'footer':
                    element = this.$refs.footer.$el; break;
            }
            const top = element.offsetTop;
            window.scrollTo(0, top);
        },
    },
};
</script>

<style scoped>
.ytm-features-cards {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: max(6vh, 4vw);
}
</style>