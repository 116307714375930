<template>
  <div class="ytm-feature-wrapper">
    <h2 class="ytm-landing-font ytm-feature-head">
      {{head}}
      <span v-if="todo" class="ytm-landing-font ytm-feature-todo">скоро</span>
    </h2>
    <p class="ytm-landing-font ytm-feature-text">
      {{desc}}
    </p>
    <div class="ytm-feature-icon-wrapper">
      <img class="ytm-feature-icon" :src="icon"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature',
  props: {
    head: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    todo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.ytm-feature-wrapper {
  width: max(16vw, 10rem);
  position: relative;
  margin-bottom: max(4vh, 3vw);
  padding: max(1.8vw, 2vh) max(2vw, 1vh);
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: max(1.5vw, 1.5vh);
}

.ytm-feature-head {
  margin-bottom: max(1vw, 0.8rem);
  font-weight: 600;
  font-size: max(1.15vw, 0.8rem);
  color: #191847;
}

.ytm-feature-todo {
  border: 1px solid #9747FF;
  border-radius: 6px;
  color: #9747FF;
  padding: 1px 2px;
  font-size: max(0.7vw, 0.6rem);
}

.ytm-feature-text {
  font-weight: 400;
  font-size: max(0.9vw, 0.6rem);
  color: #191847;
}

.ytm-feature-icon-wrapper {
  position: absolute;
  right: 0;
  bottom: calc(max(3vw, 4vh) / -2);
  width: max(3vw, 4vh);
  height: max(3vw, 4vh);
  background-color: #07A8F0;
  border: none;
  border-radius: 50%;
}

.ytm-feature-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  width: 60%;
  height: 60%;
  filter: invert(1);
}
</style>